export class FFTable {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFTable";
    this.scene = "FFTable";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });

    this.data.push({
      name: "Category Title",
      value: {
        text:
          options?.game?.description
            ?.split(" - ")?.[0]
            ?.replace("BOULDER Women", "WOMEN'S BOULDER")
            ?.replace("BOULDER Men", "MEN'S BOULDER") || "",
      },
    });

    if (options.podium) {
      this.data.push({
        name: "Category subtitle",
        value: {
          text:  "Podium Challengers",
        },
      });
    }else {
    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });
  }
    
    this.data.push({
      name: "Show Split",
      value: {
        text: options?.showSplit,
      },
    });

    let results;
    if (options.web) {
      results = options.game?.results;
    } else {
      results = options.ranks?.map((rank) => {
        let obj = options.game?.manual_results?.find(
          (p) => parseInt(p.athlete_id) === parseInt(rank?.athlete_id)
        );

        return { ...obj, rank: rank?.rank };
      });
    }
let length = 10;
let start = 0;
if (options.podium) {
length = options.podium_length
}

if (options.page === 2) {
  start = length;
  }
    new Array(10).fill(1)?.map((_, index) => {
      index = start + index
      let result = results[index];

      let athlete = options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
        (a) => parseInt(a?.athlete?.ifsc_id) === parseInt(result?.athlete_id)
      );
if (options.podium && options.podium_length < index) {
  athlete = {}
  result = undefined
}
      let row = (index + 1) - start;
      this.data.push({
        name: row + " Athlete First Name",
        value: {
          text: athlete?.athlete?.first_name || "",
        },
      });
      this.data.push({
        name: row + " Athlete Last Name",
        value: {
          text: athlete?.athlete?.last_name || "",
        },
      });
      this.data.push({
        name: row + " Athlete Full Name",
        value: {
          text:
            (athlete?.athlete?.first_name || "") +
            " " +
            (athlete?.athlete?.last_name || ""),
        },
      });
      this.data.push({
        name: row + " Athlete Country",
        value: {
          text: athlete?.athlete?.country_of_birth || "",
        },
      });
      this.data.push({
        name: row + " Athlete Country Flag",
        value: {
          image: "Flags/" + athlete?.athlete?.country_of_birth + ".png",
        },
      });

      this.data.push({
        name: row + " Athlete Position",
        value: {
          text: result?.rank || "",
        },
      });
      this.data.push({
        name: row + " Athlete Bib",
        value: {
          text: result?.bib || "",
        },
      });
      this.data.push({
        name: row + " Overall Points",
        value: {
          text: result?.score || "",
        },
      });
      this.data.push({
        name: row + " Top",
        value: {
          text: result?.ascents?.filter((a) => a.top)?.length || "0",
          visible: result !== undefined,
        },
      });
      this.data.push({
        name: row + " Zone",
        value: {
          text: result?.ascents?.filter((a) => a.zone)?.length || "0",
          visible: result !== undefined,
        },
      });
      this.data.push({
        name: row + " Top Attempts",
        value: {
          text: result?.ascents?.reduce((prev, curr) => {
            return prev + (curr.top_tries || 0);
          }, 0),
          visible: result !== undefined,
        },
      });
      this.data.push({
        name: row + " Zone Attempts",
        value: {
          text: result?.ascents?.reduce((prev, curr) => {
            return prev + (curr.zone_tries || 0);
          }, 0),
          visible: result !== undefined,
        },
      });
      new Array(4)?.fill(1)?.map((_, index) => {
        let ascent = result?.ascents?.[index];
        let row_2 = index + 1;
        this.data.push({
          name: row + " Athlete Route " + row_2 + " Image",
          value: {
            image: ascent?.top
              ? "ICON_Top.png"
              : ascent?.zone
              ? "ICON_Zone.png"
              : ascent?.status === "active"
              ? "ICON_ClimbingNow.png"
              : ascent?.status === "completed"
              ? "ICON_FailedToClimb.png"
              : "ICON_YetToClimb.png",
            visible: result !== undefined,
          },
        });
        this.data.push({
          name: row + " Athlete Route " + row + " Top",
          value: {
            text: ascent?.top || "",
          },
        });
        this.data.push({
          name: row + " Athlete Route " + row + " Top Tries",
          value: {
            text: ascent?.top_tries || "",
          },
        });
        this.data.push({
          name: row + " Athlete Route " + row + " Zone",
          value: {
            text: ascent?.zone || "",
          },
        });
        this.data.push({
          name: row + " Athlete Route " + row + " Zone Tries",
          value: {
            text: ascent?.zone_tries || "",
          },
        });
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
