export class FFHeadtohead {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFHeadtohead";
    this.scene = "FFHeadtohead";
    this.data = [];
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  async preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });

    this.data.push({
      name: "Category Title",
      value: {
        text: options?.game?.description?.split(" - ")?.[0] || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });

    for (let i = 0; i < options.game?.speed_elimination_stages?.length; i++) {
      let stage = options.game?.speed_elimination_stages?.[i];
      for (let x = 0; x < stage?.heats?.length; x++) {
        let heat = stage?.heats?.[x];
        if (heat?.number === options.selectedHeat) {
          let athlete_left =
            options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
              (a) =>
                parseInt(a.athlete?.ifsc_id) ===
                parseInt(heat?.athletes?.[0]?.athlete_id)
            )?.athlete;
          let athlete_right =
            options?.game?.groups?.[0]?.routes?.[1]?.athletes?.find(
              (a) =>
                parseInt(a.athlete?.ifsc_id) ===
                parseInt(heat?.athletes?.[1]?.athlete_id)
            )?.athlete;
          let h2h_data = await fetch(
            "https://ifsc.results.info/api/v1/athletes/" +
              athlete_left?.ifsc_id +
              "/compare_to/" +
              athlete_right?.ifsc_id,
            {
              headers: {
                "x-auth-token": "cc302ae6aa3b7743f216dcecbfc4b9f8850ba401",
              },
            }
          )?.then((res) => res.json());

          this.data.push({
            name: "1 Athlete Headshot",
            value: {
              image:
                ("MEN_HS/" +
                athlete_left.first_name +
                "_" +
                this.toTitleCase(athlete_left.last_name?.trim()))
                ?.replaceAll(' ', '_')?.replace("O'neil", 'ONeil')
                ?.replace('Nimród_Sebestyén_Tusnády_', 'Nimrod_Sebestyen_TUSNADY')
                ?.replace('Nimród_Sebestyén_Tusnády', 'Nimrod_Sebestyen_TUSNADY')
                ?.replace('Qun_Tao_Ke', 'Qun_Tao_KE')
                ?.replace('Yanfai_Huy_', 'Huy_Yanfai')
                ?.replace('Yanfai_Huy', 'Huy_Yanfai')
                ?.replace('Alexandre_Yan_Hau_Hang_Sang', 'Alexandre_Yan_HAU_HANG_SANG')
                ?.replace('Daygoro_Nuñez', 'Daygoro_Nunez')
                ?.replace('Nelson_Edgardo_Linares_Arias', 'Nelson_Edgardo_LINARES_ARIAS')
                ?.replace('Carlos_Ernesto_Moreno_Arévalo', 'Carlos_Ernesto_MORENO_AREVALO')
                ?.replace('Shaneel_Yogendranath_Misra_Mohabeer', 'Shaneel_Yogendranath_Misra_MOHABEER')
                ?.replace('Bilguun_Battulga', 'Bilguun_BATTULGA')
                ?.replace('Melissa_Tatiana_Ramírez_Esperanza', 'Melissa_Tatiana_Ramirez_Esperanza')
                ?.replace('Susana_Alemán_Vega', 'Susana_Aleman')
                ?.replace('Chuluunbaatar', 'Chuluunbaatar'?.toUpperCase()) +
                ".png", 
            },
          });

          this.data.push({
            name: "1 Athlete First Name",
            value: {
              text:
                athlete_left?.first_name ||
                heat?.athletes?.[0]?.firstname ||
                "",
            },
          });
          this.data.push({
            name: "1 Athlete Last Name",
            value: {
              text:
                athlete_left?.last_name || heat?.athletes?.[0]?.lastname || "",
            },
          });

          this.data.push({
            name: "1 Athlete Full Name",
            value: {
              text:
                (athlete_left?.first_name ||
                  heat?.athletes?.[0]?.firstname ||
                  "") +
                " " +
                (athlete_left?.last_name ||
                  heat?.athletes?.[0]?.lastname ||
                  ""),
            },
          });

          this.data.push({
            name: "1 Athlete Country",
            value: {
              text:
                athlete_left?.country_of_birth ||
                heat?.athletes?.[0]?.country ||
                "",
            },
          });
          this.data.push({
            name: "1 Athlete Country Flag",
            value: {
              image: "Flags/" + athlete_left?.country_of_birth + ".png",
            },
          });

          this.data.push({
            name: "1 Athlete Age",
            value: {
              text: athlete_left.age || "",
            },
          });
          this.data.push({
            name: "1 Athlete Height",
            value: {
              text: athlete_left.height ? athlete_left.height + "cm" : "",
            },
          });

          this.data.push({
            name: "1 Athlete PB",
            value: {
              text: parseFloat(athlete_left?.speed_personal_best?.time),
            },
          });

          this.data.push({
            name: "1 Athlete Season PB",
            value: {
              text: parseFloat(athlete_left?.speed_seasonal_best?.score) || "",
            },
          });
          this.data.push({
            name: "1 Athlete Progression Rate",
            value: {
              text: athlete_left?.progression_rates?.speed?.final + "%",
            },
          });
          this.data.push({
            name: "1 Athlete Head 2 Head",
            value: {
              text: h2h_data?.athletes?.[0]?.head_to_head?.speed || "",
            },
          });
          this.data.push({
            name: "2 Athlete Headshot",
            value: {
              image:
                ("MEN_HS/" +
                athlete_right.first_name +
                "_" +
                this.toTitleCase(athlete_right.last_name?.trim()) )?.replaceAll(' ', '_')?.replace("O'neil", 'ONeil')
                ?.replace('Nimród_Sebestyén_Tusnády_', 'Nimrod_Sebestyen_TUSNADY')
                ?.replace('Nimród_Sebestyén_Tusnády', 'Nimrod_Sebestyen_TUSNADY')
                ?.replace('Qun_Tao_Ke', 'Qun_Tao_KE')
                ?.replace('Yanfai_Huy_', 'Huy_Yanfai')
                ?.replace('Yanfai_Huy', 'Huy_Yanfai')
                ?.replace('Alexandre_Yan_Hau_Hang_Sang', 'Alexandre_Yan_HAU_HANG_SANG')
                ?.replace('Daygoro_Nuñez', 'Daygoro_Nunez')
                ?.replace('Nelson_Edgardo_Linares_Arias', 'Nelson_Edgardo_LINARES_ARIAS')
                ?.replace('Carlos_Ernesto_Moreno_Arévalo', 'Carlos_Ernesto_MORENO_AREVALO')
                ?.replace('Shaneel_Yogendranath_Misra_Mohabeer', 'Shaneel_Yogendranath_Misra_MOHABEER')
                ?.replace('Bilguun_Battulga', 'Bilguun_BATTULGA')
                ?.replace('Melissa_Tatiana_Ramírez_Esperanza', 'Melissa_Tatiana_Ramirez_Esperanza')
                ?.replace('Susana_Alemán_Vega', 'Susana_Aleman')
                ?.replace('Chuluunbaatar', 'Chuluunbaatar'?.toUpperCase()) +
                ".png",
            },
          });

          this.data.push({
            name: "2 Athlete First Name",
            value: {
              text:
                athlete_right?.first_name ||
                heat?.athletes?.[1]?.firstname ||
                "",
            },
          });
          this.data.push({
            name: "2 Athlete Last Name",
            value: {
              text:
                athlete_right?.last_name || heat?.athletes?.[1]?.lastname || "",
            },
          });

          this.data.push({
            name: "2 Athlete Full Name",
            value: {
              text:
                (athlete_right?.first_name ||
                  heat?.athletes?.[1]?.firstname ||
                  "") +
                " " +
                (athlete_right?.last_name ||
                  heat?.athletes?.[1]?.lastname ||
                  ""),
            },
          });

          this.data.push({
            name: "2 Athlete Country",
            value: {
              text:
                athlete_right?.country_of_birth ||
                heat?.athletes?.[1]?.country ||
                "",
            },
          });
          this.data.push({
            name: "2 Athlete Country Flag",
            value: {
              image: "Flags/" + athlete_right?.country_of_birth + ".png",
            },
          });
          this.data.push({
            name: "2 Athlete PB",
            value: {
              text: parseFloat(athlete_right?.speed_personal_best?.time),
            },
          });
          this.data.push({
            name: "2 Athlete Season PB",
            value: {
              text: parseFloat(athlete_right?.speed_seasonal_best?.score),
            },
          });

          this.data.push({
            name: "2 Athlete Age",
            value: {
              text: athlete_right.age || "",
            },
          });
          this.data.push({
            name: "2 Athlete Height",
            value: {
              text: athlete_right.height ? athlete_right.height + "cm" : "",
            },
          });

          this.data.push({
            name: "2 Athlete Progression Rate",
            value: {
              text: athlete_right?.progression_rates?.speed?.final + "%",
            },
          });
          this.data.push({
            name: "2 Athlete Head 2 Head",
            value: {
              text: h2h_data?.athletes?.[1]?.head_to_head?.speed || "",
            },
          });
        }
      }
    }

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
