export class BoulderBugs {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "BoulderBugs";
    this.scene = "BoulderBugs";
    this.data = [];
  }


  preview({ options }) {
    this.onAir = false;
    this.data = [];
    
    for (let i =0;i<4;i++) {
      this.data.push({
        name: "Boulder " + (i+1),
        value: {
          text: options?.boulders?.[i] ?? '',
        },
      });
    
    }
   

    
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    for (let i =0;i<4;i++) {
      debugger
      this.data.push({
        name: "Boulder " + (i+1),
        value: {
          text: options?.boulders?.[i] ?? '',
         
        },
      });
    }
   

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
