export class BoulderBug {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "BoulderBug";
    this.scene = "BoulderBug";
    this.data = [];
  }


  preview({ options }) {
    this.onAir = false;
    this.data = [];
    
  
      this.data.push({
        name: "Boulder",
        value: {
          text: options?.boulder?? '',
        },
      });
  
    
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
  
      this.data.push({
        name: "Boulder",
        value: {
          text: options?.boulder ?? '',
         
        },
      });
    
   

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
