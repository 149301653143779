import moment from "moment";

export class Clock {
  constructor({ animate, preview, update, animateOff, triggerAnimation }) {
    this.onAir = false;
    this.time = 0;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.name = "CLOCK";
    this.scene = "Clock";
    this.data = [];
    this.home_score = 0;
    this.away_score = 0;
    this.period_number = 0;
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [{ name: "Clock", value: { text: options.clock } }];
    this.sendPreview(this.scene, 25, this.data, "IN");
    this.time++;
  }
  animate({ options }) {
    this.onAir = true;
    this.added_time_on_air = false;
    this.data = [];

    let pauseTime = 0;

    options.period.pause &&
      options.period.pause.forEach((pause) => {
        if (pause.end) {
          pauseTime += pause.end - pause.start;
        }
      });
    let latestPause =
      options.period.pause &&
      options.period.pause[options.period.pause.length - 1];
    if (latestPause && latestPause.end) {
      latestPause = null;
    }

    this.data.push({
      name: "Clock Type",
      value: {
        text:'manual'
      },
    });

    this.data.push({
      name: "Clock",
      value: {
        action: latestPause ? "reset" : "start",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.added_time_on_air = false;
    this.sendAnimateOff(this.scene, []);
  }
  update({ options }) {
    this.data = [];

    this.data = [];

    if (options.type === "PAUSE") {
      this.pause({ options });
    } else if (options.type === "RESUME") {
      this.resume({ options });
    }
  }
  getSeconds({ period, game }) {
    if (period.kick_off) {
      let pauseTime = 0;

      period.pause &&
        period.pause.forEach((pause) => {
          if (pause.end) {
            pauseTime += pause.end - pause.start;
          }
        });
      let latestPause = period.pause && period.pause[period.pause.length - 1];
      if (latestPause && latestPause.end) {
        latestPause = null;
      }
      let time = moment.duration(
        ((latestPause && latestPause.start) || Date.now()) -
          period.kick_off -
          pauseTime
      );

      let minutes = time.minutes() + time.hours() * 60;

      let mins = minutes;
      let secs = time.seconds();
      let total_seconds = mins * 60 + secs;

      return 5 * 60 - total_seconds - 1;
    }

    return 5 * 60;
  }

  pause({ options }) {
    let data = [];

    data.push({
      name: "Clock",
      value: {
        action: "reset",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });
    this.sendUpdate(this.scene, data);
  }
  resume({ options }) {
    let data = [];

    data.push({
      name: "Clock",
      value: {
        action: "start",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });
    this.sendUpdate(this.scene, data);
  }
}
