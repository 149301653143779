import React, { useEffect, useState, useContext, useCallback } from "react";
import styled from "styled-components";
import moment from "moment";
import { UIContext, VIEW_CONSTS } from "../../contexts/UIContext";
import { DataContext } from "../../contexts/DataContext";
import { FiPlay } from "react-icons/fi";
import { AiOutlinePause } from "react-icons/ai";
import { Button } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";
import { useHistory, useLocation } from "react-router";
import { ClockContext } from "../../contexts/ClockContext";

const Main = styled.div`
  display: flex;
  font-weight: 100;
  box-shadow: 0px 5px 3px 0 rgb(0 0 0 / 12%);
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  z-index: 0;
  border-bottom: 1px solid #3d485d;
`;
const ClockSection = styled.div`
  display: flex;
  min-width: 70px;
  height: 90px;
  justify-content: ${(props) => (props.mins ? "flex-end" : "flex-start")};
`;
const ClockSplit = styled.div`
  height: 90px;
`;
const Half = styled.div`
  display: flex;
  font-size: 1.5em;
`;

const ClockContainer = styled.div`
  display: flex;
  font-size: 4em;
  align-items: center;
  width: 100%;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
`;
const ClockButton = styled.div`
  font-size: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.green ? "#0adb93" : "#db0a41")};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 100%;
`;
const ResetButton = styled.div`
  font-size: 2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 70%;
  background-color: #181d25;
  margin-top: 50px;
  margin-bottom: 50px;
`;
const AddedTime = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
`;
const AddedTimeButton = styled.div`
  font-size: 1em;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  display: flex;
  background-color: ${(props) => (props.selected ? "#db0a41" : "#181d25")};

  /* width: 100%; */
  justify-content: center;
  margin: 0.2em;
  width: 25%;
  box-sizing: border-box;
  :hover {
    background-color: #db0a41;
  }
`;
const PauseResumeButton = styled(Button)`
  font-size: 0.3em;
  padding: 0.5em;
  font-weight: 100;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
const ClockDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default function StadiumClock({
  game,
  teams,
  previewGraphic,
  animateGraphic,
  animateOffGraphic,
  updateGraphic,
  group,
}) {
  const history = useHistory();
  const location = useLocation();
  const {auto_clock} = useContext(ClockContext) 

  return (
    <Main>
   
        <ClockContainer>
      
          <ClockDiv
           
          >
            <ClockSection mins>{auto_clock}</ClockSection>
            
          </ClockDiv>
         
        </ClockContainer>
      
     
     
        <Column>
          <Buttons>
            <ClockButton
              onClick={() => {
                animateOffGraphic("CLOCKSTADIUM", {});
              }}
            >
              OUT
            </ClockButton>

            <ClockButton
              onClick={() => {
                animateGraphic("CLOCKSTADIUM", {
                  
                });
              }}
              green
            >
              IN
            </ClockButton>
          </Buttons>
        </Column>
      
    </Main>
  );
}
