import moment from "moment";

export class ClockStadium {
  constructor({ animate, preview, update, animateOff, triggerAnimation }) {
    this.onAir = false;
    this.time = 0;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.triggerAnimation = triggerAnimation;
    this.name = "CLOCKSTADIUM";
    this.scene = "Clock";
    this.data = [];
    this.home_score = 0;
    this.away_score = 0;
    this.period_number = 0;
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [{ name: "Clock", value: { text: options.clock } }];
    this.sendPreview(this.scene, 25, this.data, "IN");
    this.time++;
  }
  animate({ options }) {
    this.onAir = true;
    this.added_time_on_air = false;
    this.data = [];

    this.data.push({
      name: "Clock Type",
      value: {
        text:'stadium'
      },
    });

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.added_time_on_air = false;
    this.sendAnimateOff(this.scene, []);
  }
}
