import React, { useState, useEffect } from "react";
const ClockContext = React.createContext([{}, () => {}]);
window.scenesOnAir = [];

window.control_ws = null;

const ClockProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const [auto_clock, setAutoClock] = useState();

  useEffect(() => {
    connect();
  }, []);

  function connect() {
    console.log("Clock connecting to server");
    try {
      window.clock_ws = new WebSocket(
        process.env.REACT_APP_CLOCK_WSS ||
          window.ENV?.REACT_APP_CLOCK_WSS 
      );
      setWS(window.clock_ws);
    } catch (err) {}
  }

  useEffect(() => {
    if (window.clock_ws) {
      let timeout;
      window.clock_ws.onopen = () => {
        setWSStatus(1);
        console.log("Climbing Control on open");
        clearInterval(window.clock_keep_alive);
        window.clock_keep_alive = setInterval(() => {
          window.clock_ws.send(
            JSON.stringify({
              keep_alive: Date.now(),
              group: window.id,
            })
          );
        }, 20000);
      };
      window.clock_ws.onmessage = (data) => {
        //console.log("Clock on message");
        try {
          let obj = JSON.parse(data.data);

          if (obj.hyperTime?.Climbing) {
            setAutoClock(obj.hyperTime?.Climbing?.time)
          }

          //console.log(obj);
        } catch (err) {
          console.error(err);
        }
      };
      window.clock_ws.onerror = (err) => {
        console.log("Clock on message", err);
        ws.close();
      };
      window.clock_ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Clock on close");
        clearTimeout(timeout);
        clearInterval(window.clock_keep_alive);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  return (
    <ClockContext.Provider
      value={{
        wsStatus,
        auto_clock,
    
      }}
    >
      {props.children}
    </ClockContext.Provider>
  );
};

export { ClockContext, ClockProvider };
