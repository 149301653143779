export class Run {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "RUN";
    this.scene = "Run";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    
    this.data.push({
      name: "Run",
      value: {
        text:   (options?.run === undefined || options?.run === 1 ? '1' : "2") + '/2',
      },
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;
    this.data.push({
      name: "Run",
      value: {
        text:   (options?.run === undefined || options?.run === 1 ? '1' : "2") + '/2',
      },
    });
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
