export class FFMatchUp4 {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFMatchUp4";
    this.scene = "FFMatchUp4";
    this.data = [];
  }

  toTitleCase(str) {
    if (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return str
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });

    this.data.push({
      name: "Category Title",
      value: {
        text: options?.game?.description?.split(" - ")?.[0] || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });
if (options.activeRoute) {
  this.buildBoulderManual({route: '1', row: 1, options})
  this.buildBoulderManual({route: '2', row: 2, options})
  this.buildBoulderManual({route: '3', row: 3, options})
  this.buildBoulderManual({route: '4', row: 4, options})
}else {
this.buildBoulder({route: '1', row: 1, options})
this.buildBoulder({route: '2', row: 2, options})
this.buildBoulder({route: '3', row: 3, options})
this.buildBoulder({route: '4', row: 4, options})
}

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  buildBoulder({row, route, options}) {
    let boulder =  [...(options.game?.results || [])]?.find(p=>p?.ascents?.find(a=>a?.route_name === route && a.status === 'active'))
  
        let athlete = options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
          (a) => parseInt(a?.athlete?.ifsc_id) === parseInt(boulder?.athlete_id)
        );
        

        this.data.push({
          name: row + " Athlete Headshot",
          value: {
            image:
              ("MEN_HS/" +
              athlete?.athlete?.first_name +
              "_" +
              this.toTitleCase(athlete?.athlete?.last_name))?.replaceAll(' ', '_')?.replace("O'neil", 'ONeil')
              ?.replace('Nimród_Sebestyén_Tusnády_', 'Nimrod_Sebestyen_TUSNADY')
              ?.replace('Nimród_Sebestyén_Tusnády', 'Nimrod_Sebestyen_TUSNADY')
              ?.replace('Qun_Tao_Ke', 'Qun_Tao_KE')
              ?.replace('Yanfai_Huy_', 'Huy_Yanfai')
              ?.replace('Yanfai_Huy', 'Huy_Yanfai')
              ?.replace('Alexandre_Yan_Hau_Hang_Sang', 'Alexandre_Yan_HAU_HANG_SANG')
              ?.replace('Daygoro_Nuñez', 'Daygoro_Nunez')
              ?.replace('Nelson_Edgardo_Linares_Arias', 'Nelson_Edgardo_LINARES_ARIAS')
              ?.replace('Carlos_Ernesto_Moreno_Arévalo', 'Carlos_Ernesto_MORENO_AREVALO')
              ?.replace('Shaneel_Yogendranath_Misra_Mohabeer', 'Shaneel_Yogendranath_Misra_MOHABEER')
              ?.replace('Bilguun_Battulga', 'Bilguun_BATTULGA')
              ?.replace('Melissa_Tatiana_Ramírez_Esperanza', 'Melissa_Tatiana_Ramirez_Esperanza')
              ?.replace('Susana_Alemán_Vega', 'Susana_Aleman')
              ?.replace('Chuluunbaatar', 'Chuluunbaatar'?.toUpperCase()) +
              ".png",
          },
        });

        this.data.push({
          name: row + " Athlete First Name",
          value: {
            text: athlete?.athlete?.first_name || "",
          },
        });
        this.data.push({
          name: row + " Athlete Last Name",
          value: {
            text: athlete?.athlete?.last_name || "",
          },
        });
        this.data.push({
          name: row + " Athlete Full Name",
          value: {
            text:
              (athlete?.athlete?.first_name || "") +
              " " +
              (athlete?.athlete?.last_name || ""),
          },
        });
        this.data.push({
          name: row + " Athlete Country",
          value: {
            text: athlete?.athlete?.country_of_birth || "",
          },
        });
        this.data.push({
          name: row + " Athlete Country Flag",
          value: {
            image: "Flags/" + athlete?.athlete?.country_of_birth + ".png",
          },
        });

        this.data.push({
          name: row + " Athlete Bib",
          value: {
            text: athlete?.bib || "",
          },
        });
      
  }
  buildBoulderManual({row, route, options}) {
        let athlete = options?.game?.groups?.[0]?.routes?.[0]?.athletes?.find(
          (a) => parseInt(a?.athlete?.ifsc_id) === parseInt(options.activeRoute?.find(a=>a.route ===row-1)?.athlete)
        );
        

        this.data.push({
          name: row + " Athlete Headshot",
          value: {
            image:
              ("MEN_HS/" +
              athlete?.athlete?.first_name +
              "_" +
              this.toTitleCase(athlete?.athlete?.last_name))?.replaceAll(' ', '_')?.replace("O'neil", 'ONeil')
              ?.replace('Nimród_Sebestyén_Tusnády_', 'Nimrod_Sebestyen_TUSNADY')
              ?.replace('Nimród_Sebestyén_Tusnády', 'Nimrod_Sebestyen_TUSNADY')
              ?.replace('Qun_Tao_Ke', 'Qun_Tao_KE')
              ?.replace('Yanfai_Huy_', 'Huy_Yanfai')
              ?.replace('Yanfai_Huy', 'Huy_Yanfai')
              ?.replace('Alexandre_Yan_Hau_Hang_Sang', 'Alexandre_Yan_HAU_HANG_SANG')
              ?.replace('Daygoro_Nuñez', 'Daygoro_Nunez')
              ?.replace('Nelson_Edgardo_Linares_Arias', 'Nelson_Edgardo_LINARES_ARIAS')
              ?.replace('Carlos_Ernesto_Moreno_Arévalo', 'Carlos_Ernesto_MORENO_AREVALO')
              ?.replace('Shaneel_Yogendranath_Misra_Mohabeer', 'Shaneel_Yogendranath_Misra_MOHABEER')
              ?.replace('Bilguun_Battulga', 'Bilguun_BATTULGA')
              ?.replace('Melissa_Tatiana_Ramírez_Esperanza', 'Melissa_Tatiana_Ramirez_Esperanza')
              ?.replace('Susana_Alemán_Vega', 'Susana_Aleman')
              ?.replace('Chuluunbaatar', 'Chuluunbaatar'?.toUpperCase()) +
              ".png",
          },
        });

        this.data.push({
          name: row + " Athlete First Name",
          value: {
            text: athlete?.athlete?.first_name || "",
          },
        });
        this.data.push({
          name: row + " Athlete Last Name",
          value: {
            text: athlete?.athlete?.last_name || "",
          },
        });
        this.data.push({
          name: row + " Athlete Full Name",
          value: {
            text:
              (athlete?.athlete?.first_name || "") +
              " " +
              (athlete?.athlete?.last_name || ""),
          },
        });
        this.data.push({
          name: row + " Athlete Country",
          value: {
            text: athlete?.athlete?.country_of_birth || "",
          },
        });
        this.data.push({
          name: row + " Athlete Country Flag",
          value: {
            image: "Flags/" + athlete?.athlete?.country_of_birth + ".png",
          },
        });

        this.data.push({
          name: row + " Athlete Bib",
          value: {
            text: athlete?.bib || "",
          },
        });
      
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
