export class AthleteActive {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Athlete Active";
    this.scene = "Athlete Active";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });
    this.data.push({
      name: "Category Title",
      value: {
        text: options?.game?.description?.split(" - ")?.[0] || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });
    this.data.push({
      name: "Athlete First Name",
      value: {
        text: options.player?.first_name || "",
      },
    });
    this.data.push({
      name: "Athlete Last Name",
      value: {
        text: options.player?.last_name || "",
      },
    });
    this.data.push({
      name: "Athlete Full Name",
      value: {
        text:
          (options.player?.first_name || "") +
          " " +
          (options.player?.last_name || ""),
      },
    });
    this.data.push({
      name: "Athlete Bib",
      value: {
        text: options.player?.bib || "",
      },
    });
    this.data.push({
      name: "Athlete Country",
      value: {
        text: options.player?.country_of_birth || "",
      },
    });
    this.data.push({
      name: "Athlete Country Flag",
      value: {
        image: "Flags/" + options.player?.country_of_birth + ".png",
      },
    });
    this.data.push({
      name: "Athlete Age",
      value: {
        text: options.player?.age || "",
      },
    });
    this.data.push({
      name: "Athlete Height",
      value: {
        text: options.player?.height || "",
      },
    });
    this.data.push({
      name: "Athlete Position",
      value: {
        text: options.player?.rank || "",
      },
    });

    if (options.group?.name === "Qualification") {
    this.data.push({
      name: "Run",
      value: {
        text: '',
      },
    });
  } else {
    this.data.push({
      name: "Run",
      value: {
        text: (options.player?.run === undefined || options.player?.run ===1 ? '1' : "2") + '/2',
      },
    });
  }

    this.data.push({
      name: "Top",
      value: {
        text: options.player?.ascents?.filter((a) => a.top)?.length || "0",
      },
    });
    this.data.push({
      name: "Zone",
      value: {
        text: options.player?.ascents?.filter((a) => a.zone)?.length || "0",
      },
    });
    this.data.push({
      name: "Top Attempts",
      value: {
        text: options.player?.ascents?.reduce((prev, curr) => {
          return prev + (curr.top_tries || 0);
        }, 0),
      },
    });
    this.data.push({
      name: "Zone Attempts",
      value: {
        text: options.player?.ascents?.reduce((prev, curr) => {
          return prev + (curr.zone_tries || 0);
        }, 0),
      },
    });
    
    this.data.push({
      name: "Overall Points",
      value: {
        text: options.player?.overall_points,
      },
    });

    let last = options.player?.ascents?.at(-1);

    this.data.push({
      name: "Active Top Attempts",
      value: {
        text:
          options.player?.ascents?.find((a) => a.status === "active")
            ?.top_tries ||
          options.player?.ascents
            ?.filter((a) => a.status === "confirmed")
            ?.at(-1)?.top_tries ||
          0,
      },
    });
    this.data.push({
      name: "Active Zone Attempts",
      value: {
        text:
          options.player?.ascents?.find((a) => a.status === "active")
            ?.zone_tries ||
          options.player?.ascents
            ?.filter((a) => a.status === "confirmed")
            ?.at(-1)?.zone_tries ||
          0,
      },
    });
    this.data.push({
      name: "Active Route Name",
      value: {
        text:
          options?.routeName?.[
            options?.player?.ascents?.findIndex((r) => r.status === "active")
          ] || "",
      },
    });
    new Array(4).fill(1)?.map((_, index) => {
      let ascent = options?.player?.ascents?.[index];
      let row = index + 1;
      this.data.push({
        name: "Route " + row + " Image",
        value: {
          image: ascent?.top
            ? "ICON_Top.png"
            : ascent?.zone
            ? "ICON_Zone.png"
            : ascent?.status === "active"
            ? "ICON_ClimbingNow.png"
            : ascent?.status === "completed"
            ? "ICON_FailedToClimb.png"
            : "ICON_YetToClimb.png",
        },
      });
      this.data.push({
        name: "Route " + row + " Top",
        value: {
          text: ascent?.top,
        },
      });
      this.data.push({
        name: "Route " + row + " Top Tries",
        value: {
          text: ascent?.top_tries,
        },
      });
      this.data.push({
        name: "Route " + row + " Zone",
        value: {
          text: ascent?.zone,
        },
      });
      this.data.push({
        name: "Route " + row + " Zone Tries",
        value: {
          text: ascent?.zone_tries,
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
  update({ options }) {
    this.data = [];
    this.data.push({
      name: "Competition Title",
      value: {
        text: options?.game?.competition?.name || "",
      },
    });
    this.data.push({
      name: "Category Title",
      value: {
        text: options?.game?.description?.split(" - ")?.[0] || "",
      },
    });

    this.data.push({
      name: "Category subtitle",
      value: {
        text: options?.game?.description?.split(" - ")?.[1] || "",
      },
    });
    this.data.push({
      name: "Athlete First Name",
      value: {
        text: options.player?.first_name || "",
      },
    });
    this.data.push({
      name: "Athlete Last Name",
      value: {
        text: options.player?.last_name || "",
      },
    });
    this.data.push({
      name: "Athlete Full Name",
      value: {
        text:
          (options.player?.first_name || "") +
          " " +
          (options.player?.last_name || ""),
      },
    });
    this.data.push({
      name: "Athlete Bib",
      value: {
        text: options.player?.bib || "",
      },
    });
    this.data.push({
      name: "Athlete Country",
      value: {
        text: options.player?.country_of_birth || "",
      },
    });
    this.data.push({
      name: "Athlete Country Flag",
      value: {
        image: "Flags/" + options.player?.country_of_birth + ".png",
      },
    });
    this.data.push({
      name: "Athlete Age",
      value: {
        text: options.player?.age || "",
      },
    });
    this.data.push({
      name: "Athlete Height",
      value: {
        text: options.player?.height || "",
      },
    });
    this.data.push({
      name: "Athlete Position",
      value: {
        text: options.player?.rank || "",
      },
    });

    if (options.group?.name === "Qualification") {
      this.data.push({
        name: "Run",
        value: {
          text: '',
        },
      });
    } else {
      this.data.push({
        name: "Run",
        value: {
          text: (options.player?.run === undefined || options.player?.run ===1 ? '1' : "2") + '/2',
        },
      });
    }

    
    this.data.push({
      name: "Overall Points",
      value: {
        text: options.player?.overall_points,
      },
    });
    
    this.data.push({
      name: "Top",
      value: {
        text: options.player?.ascents?.filter((a) => a.top)?.length || "0",
      },
    });
    this.data.push({
      name: "Zone",
      value: {
        text: options.player?.ascents?.filter((a) => a.zone)?.length || "0",
      },
    });
    this.data.push({
      name: "Top Attempts",
      value: {
        text: options.player?.ascents?.reduce((prev, curr) => {
          return prev + (curr.top_tries || 0);
        }, 0),
      },
    });
    this.data.push({
      name: "Zone Attempts",
      value: {
        text: options.player?.ascents?.reduce((prev, curr) => {
          return prev + (curr.zone_tries || 0);
        }, 0),
      },
    });

    this.data.push({
      name: "Active Top Attempts",
      value: {
        text:
          options.player?.ascents?.find((a) => a.status === "active")
            ?.top_tries ||
          options.player?.ascents
            ?.filter((a) => a.status === "confirmed")
            ?.at(-1)?.top_tries ||
          0,
      },
    });
    this.data.push({
      name: "Active Zone Attempts",
      value: {
        text:
          options.player?.ascents?.find((a) => a.status === "active")
            ?.zone_tries ||
          options.player?.ascents
            ?.filter((a) => a.status === "confirmed")
            ?.at(-1)?.zone_tries ||
          0,
      },
    });
    this.data.push({
      name: "Active Route Name",
      value: {
        text:
          options?.routeName?.[
            options?.player?.ascents?.findIndex((r) => r.status === "active")
          ] || "",
      },
    });
    new Array(4).fill(1)?.map((_, index) => {
      let ascent = options?.player?.ascents?.[index];
      let row = index + 1;
      this.data.push({
        name: "Route " + row + " Image",
        value: {
          image: ascent?.top
            ? "ICON_Top.png"
            : ascent?.zone
            ? "ICON_Zone.png"
            : ascent?.status === "active"
            ? "ICON_ClimbingNow.png"
            : ascent?.status === "completed"
            ? "ICON_FailedToClimb.png"
            : "ICON_YetToClimb.png",
        },
      });
      this.data.push({
        name: "Route " + row + " Top",
        value: {
          text: ascent?.top,
        },
      });
      this.data.push({
        name: "Route " + row + " Top Tries",
        value: {
          text: ascent?.top_tries,
        },
      });
      this.data.push({
        name: "Route " + row + " Zone",
        value: {
          text: ascent?.zone,
        },
      });
      this.data.push({
        name: "Route " + row + " Zone Tries",
        value: {
          text: ascent?.zone_tries,
        },
      });
    });
    this.sendUpdate(this.scene, this.data);
  }
}
