import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useLocalStorage from "../../Hooks/useLocalStorage";
import { Button } from "../../Theme/Hyper";
import Select from "react-select";
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const RouteColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
`;

const AthleteRow = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "39px" : "")};
  padding-right: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const Name = styled.div`
  min-width: 300px;
`;
const Bib = styled.div`
  min-width: 70px;
  text-align: center;
`;
const Score = styled.div`
  margin-left: 40px;
  min-width: 80px;
`;
const Ascent = styled.div`
  margin-left: 40px;
`;
const Bar = styled.div`
  min-height: 20px;
  height: 20px;
  min-width: 30px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
`;
const BarBig = styled.div`
  min-height: 120px;
  height: 120px;
  min-width: 60px;
  border: 1px solid #dfe9e8;
  display: flex;
  flex-flow: column-reverse;
`;
const InnerBar = styled.div`
  display: flex;
  min-height: ${(props) => (props.top ? "100%" : props.zone ? "50%" : "0px")};
  min-width: 100%;
  background-color: #0adb93;
  margin-top: auto;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const AscentColumn = styled.div``;

const Input = styled.input`
  background-color: #1f222b;
  border-radius: 4px;
  border: none;
  padding: 1em;
  margin-bottom: 1em;
  outline: none;
  color: #fff;
  width: 50px;
`
const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

function Web({
  selectedPlayer,
  group,
  game,
  setSelectedPlayer,
  ranks,
  setRanks,
  updateGraphic,
  routeName,
  setRouteName,
  previewGraphic,
  animateOffGraphic,
  animateGraphic, 
  showSplit, 
  setShowSplit,
  activeRoute,
  setActiveRoute,
  runs,
  setRuns
}) {
  useEffect(() => {
    setRanks(game?.results);
    if (selectedPlayer) {
      let score =  game?.results?.find(
        (s) => parseInt(s.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
      )?.score
      
      updateGraphic("Athlete Active", {
        game: game,
        group,
        player: {
          ...selectedPlayer,
          ascents: game?.results?.find(
            (r) => parseInt(r?.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
          )?.ascents,
          rank: game?.results?.find(
            (s) => parseInt(s.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
          )?.rank,
          overall_points: game?.results?.find(
            (s) => parseInt(s.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
          )?.score,
          routeName,
          run: runs?.find(a=>a.athlete === selectedPlayer?.ifsc_id)?.run
        },
        rank: game?.results?.find(
          (s) => parseInt(s.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
        )?.rank,
        overall_points: game?.results?.find(
          (s) => parseInt(s.athlete_id) === parseInt(selectedPlayer?.ifsc_id)
        )?.score,
        routeName,
      });
    }
  }, [game, selectedPlayer, runs]);
  return (
    <Column>
    <Row>
      <RouteColumn>
        <AthleteRow>
        <Bib>IFSC ID</Bib>
          <Bib>ORDER</Bib>
          <Bib>RANK</Bib>
          <Bib>BIB</Bib>
          <Bib>NAT</Bib>
          <Bib>AGE</Bib>
          <Bib>HEIGHT</Bib>
          <Name>NAME</Name>
          <Score>SCORE</Score>
        </AthleteRow>
        {[...(group?.routes?.[0]?.athletes || [])]
          ?.sort((a, b) => a.order - b.order)
          ?.map((result) => {
            
            let result_obj = game?.results?.find(
              (r) => r?.athlete_id === parseInt(result?.athlete?.ifsc_id)
            );
            return (
              <Row>
              <AthleteRow
              key={result?.athlete?.ifsc_id}
                selected={
                  parseInt(result?.athlete?.ifsc_id) ===
                  parseInt(selectedPlayer?.ifsc_id)
                }
                onClick={() => {
                  if (
                    parseInt(selectedPlayer?.ifsc_id) ===
                    parseInt(result?.athlete?.ifsc_id)
                  ) {
                    setSelectedPlayer();
                  } else {
                    setSelectedPlayer({ ...result?.athlete, bib: result?.bib });
                  }
                }}
              >
                 <Bib>{result?.athlete?.ifsc_id}</Bib>
                <Bib>{result?.order}</Bib>
                <Bib>{result_obj?.rank}</Bib>
                <Bib>{result?.bib}</Bib>
                <Bib>{result?.athlete?.country_of_birth}</Bib>
                <Bib>{result?.athlete?.age}</Bib>
                <Bib>{result?.athlete?.height}</Bib>
                <Name>
                  {result?.athlete?.first_name} {result?.athlete?.last_name}
                </Name>
                <Score>{result_obj?.score}</Score>
                {result_obj?.ascents?.map((a, index) => {
                  return (
                    <Ascent key={result?.athlete?.ifsc_id + '_' + index}>
                      <Bar>
                        <InnerBar
                          top={a?.top === true}
                          zone={a?.zone === true}
                        ></InnerBar>
                       
                      </Bar>
                    </Ascent>
                  );
                })}
               
              </AthleteRow>
              <div> <SelectContainer
          styles={DropdownStyles}
          setSize={true}
          value={{
            value:activeRoute?.find(a=>a.athlete ===result?.athlete?.ifsc_id)?.route,
            label: routeName?.[activeRoute?.find(a=>a.athlete ===result?.athlete?.ifsc_id)?.route],
          }}
          options={[...routeName.map((item, index) => {
            return {
              value: index,
              label: item,
            };
          }), {value: 999, label: 'NONE'}]}
          onChange={(e) => {
            
            let new_routes = [...activeRoute];
            new_routes = new_routes?.filter(a=>a.route !==e.value)
            new_routes = new_routes?.filter(a=>a.athlete !==result?.athlete?.ifsc_id)
            new_routes.push({route: e.value, athlete: result?.athlete?.ifsc_id})
            setActiveRoute(new_routes)
         //   setSelectedEngine(e.value);
          }}
          placeholder="Round"
        /></div>
              </Row>
            );
          })}
      </RouteColumn>
      <RouteColumn>
        {selectedPlayer && (
          <>
            <Row>
              <span>
                {selectedPlayer?.first_name} {selectedPlayer?.last_name}
              </span>
            </Row>
            {group?.name !== "Qualification" && 
            <Row>
              Run <Button style={{marginRight: '10px', backgroundColor: runs?.find(a=>a.athlete === selectedPlayer?.ifsc_id)?.run === undefined || runs?.find(a=>a.athlete === selectedPlayer?.ifsc_id)?.run === 1 ? 'green' : ''}} onClick={() =>{
               
               let new_runs = [...runs]
                new_runs = new_runs.filter(a=>a.athlete !== selectedPlayer.ifsc_id)
                new_runs.push({athlete:selectedPlayer.ifsc_id, run: 1 })
                setRuns(new_runs)
              }}>ONE</Button><Button style={{backgroundColor: runs?.find(a=>a.athlete ===selectedPlayer?.ifsc_id)?.run === 2 ? 'green' : ''}}onClick={() =>{
                let new_runs = [...runs]
                new_runs = new_runs.filter(a=>a.athlete !== selectedPlayer?.ifsc_id)
                new_runs.push({athlete:selectedPlayer.ifsc_id, run: 2 })
                setRuns(new_runs)
              }}>TWO</Button>
            </Row>
}
            <Row>
              {game?.results
                ?.find(
                  (r) => r?.athlete_id === parseInt(selectedPlayer?.ifsc_id)
                )
                ?.ascents?.map((a, index) => {
                  return (
                    <RouteColumn key={index}>
                      <Row>
                        Route Name{" "}
                        <Input
                          type="text"
                          value={routeName?.[index]}
                          onChange={(e) => {
                            let new_names = [...(routeName || [])];
                            new_names[index] = e.currentTarget.value;
                            setRouteName(new_names);
                          }}
                        />
                      </Row>

                      <AscentColumn>
                        <BarBig>
                          <InnerBar
                            top={a?.top === true}
                            zone={a?.zone === true}
                          ></InnerBar>
                        </BarBig>
                      </AscentColumn>
                      <Row>Attempts</Row>
                      <Row>Zone: {a?.zone_tries}</Row>
                      <Row>Top: {a?.top_tries}</Row>
                      <Row>{a?.status}</Row>

                    </RouteColumn>
                  );
                })}
            </Row>
            <Row style={{justifyContent:'space-around', marginTop:'100px'}}>
              {/* <Button onClick={() =>{
                 animateGraphic("CLOCKSTADIUM", {
                });
              }}>Animte In Stadium Clock</Button>
              <Button onClick={() =>{
                  animateOffGraphic("CLOCKSTADIUM", {});
              }}>Animte Off  Stadium Clock</Button> */}
              <Button onClick={() =>{
                
                 animateGraphic("RUN", {
                  run: runs?.find(a=>a.athlete ===selectedPlayer?.ifsc_id)?.run
                });
              }}>Animte In Run</Button>
              <Button onClick={() =>{
                 animateOffGraphic("RUN", {
                  run: runs?.find(a=>a.athlete ===selectedPlayer?.ifsc_id)?.run 
                });
              }}>Animte Off Run</Button>
            </Row>
          </>
        )}
      </RouteColumn>
      </Row>
      <Row style={{justifyContent:'flex-start'}}>Toggle 6th/7th split<Button style={{marginLeft: '10px', marginRight: '10px', backgroundColor: !showSplit ? 'green' :''}} onClick={()=>{
setShowSplit(false)
      }}>Off</Button><Button style={{backgroundColor: showSplit ? 'green' :''}}
      onClick={()=>{
        setShowSplit(true)
              }}>On</Button></Row>
      <Row>
        {routeName?.map((item, index)=>{

        
     return <RouteColumn key={index}>
                      <Row>
                        Route Name{" "}
                        <Input
                          type="text"
                          value={routeName?.[index]}
                          onChange={(e) => {
                            let new_names = [...(routeName || [])];
                            new_names[index] = e.currentTarget.value;
                            setRouteName(new_names);
                          }}
                        />
                      </Row>
                      <Row>
                        Bug <Button style={{marginLeft: '10px'}} onClick={()=>{
                           animateGraphic("BoulderBugs", {
                          boulders: [routeName?.[index]]
                          });
                        }}>On</Button><Button style={{marginLeft: '10px'}} onClick={()=>{
                          animateOffGraphic("BoulderBugs", {
                            
                          });
                        }}>Off</Button>
                      </Row>
                      <Row style={{marginTop: '10px'}}>
                      L3 Bug <Button style={{marginLeft: '10px'}} onClick={()=>{
                           animateGraphic("BoulderBug", {
                          boulder: routeName?.[index]
                          });
                        }}>On</Button><Button style={{marginLeft: '10px'}} onClick={()=>{
                          animateOffGraphic("BoulderBug", {
                            
                          });
                        }}>Off</Button>
                      </Row>
                      </RouteColumn>
        })}
         All Bugs <Button style={{marginLeft: '10px'}}  onClick={()=>{
                           animateGraphic("BoulderBugs", {
                            boulders: routeName
                          });
                        }}>On</Button><Button style={{marginLeft: '10px'}} onClick={()=>{
                          animateOffGraphic("BoulderBugs", {
                          });
                        }}>Off</Button>
      </Row>
    </Column>
  );
}

export default Web;
